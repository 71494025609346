$screen-sm: 420px;
$screen-md: 768px;
$screen-lg: 1024px;

$bg-dark: #005630;
$bg-light: #095;

$cta-dark: #2d78bd;
$cta-light: #5697d3;

$gray: #ccc;
$gray-light: #ddd;
$gray-lighter: #eee;
$text-color: #333;

$border-radius: 7%;
