@import 'variables';

main > nav {
  color: #fff;
  text-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.7);

  ul {
    margin: 0 0 0 0.5rem;
    padding: 0;
    list-style: none;
    float: left;

    li {
      float: left;
      margin: 0.8rem 0.5rem 0 0.5rem;

      button {
        display: block;
        cursor: pointer;
        padding: 0;
        color: #fff;
        font-size: 2rem;
        line-height: 2rem;
        background: transparent;
        border: 0;
        outline: none;
        transition: all 0.2s;
        text-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.7);

        svg {
          fill: currentColor;
          height: 2.4rem;
          width: 2.4rem;

          @media(max-width: $screen-md) {
            height: 2rem;
            width: 2rem;
          }
        }

        &:hover {
          transform: scale(1.2);
        }

        &.disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.5;

          &:hover {
            transform: none;
          }
        }
      }
    }
  }
  section {
    margin: 1rem 1.2rem;

    span {
      margin-left: 1rem;
    }

    &.highscore {
      text-align: center;

      @media(max-width: $screen-md) {
        display: none;
      }
    }
    &.score {
      float: right;
    }
  }
}
