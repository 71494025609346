.animated, %animated {
  animation-duration: 0.8s;
  animation-fill-mode: both;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada, %tada {
  animation-name: tada;
  animation-timing-function: ease-in-out;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.blink, %blink {
  animation-name: blink;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
}

@keyframes flash {
  from {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.flash, %flash {
  animation-name: flash;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}
