@import 'variables';
@import 'animate';

#deck {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: auto 5%;
  -webkit-touch-callout: none;
  user-select: none;

  &.loading {
    display: none;
  }

  @media(max-width: $screen-lg) {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }
  @media(max-width: $screen-md) {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
  }
  @media(max-width: $screen-sm) {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
  }

  .card, .gap {
    cursor: pointer;
    touch-action: none;
    line-height: 0;
    border-radius: $border-radius;
    transition: transform 0.3s, z-index 0s 0.3s;
    z-index: 1;
  }

  .card {
    img {
      width: 100%;
      height: 100%;
    }
    &.placed::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: $border-radius;
      background: linear-gradient(145deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 55%,
      rgba(0, 0, 0, 0.3) 56%,
      rgba(0, 0, 0, 0.15) 100%);
    }
    &.hint {
      @extend %animated;
      @extend %tada;
    }
    &.dragging {
      z-index: 100;
      transition: none;
      box-shadow: 0.2rem 0.2rem 0.7rem rgba(0, 0, 0, 0.3);
    }
    &.error {
      @extend %animated;
      @extend %blink;
    }
  }

  .gap {
    &.highlight {
      background: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
    }
    &.error {
      @extend %animated;
      @extend %flash;
      background: rgba(255, 0, 0, 0.5);
      border-radius: $border-radius;
    }
  }
}
