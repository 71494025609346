@import 'variables';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;

  @media(max-width: $screen-lg) {
    font-size: 14px;
  }
  @media(max-width: $screen-md) {
    font-size: 12px;
  }
  @media(max-width: $screen-sm) {
    font-size: 10px;
  }
}

body {
  color: $text-color;
  background-color: $bg-dark;
  background: radial-gradient($bg-light, $bg-dark);
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8rem;
  overflow: hidden;
}

main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  > #game {
    margin: auto;
  }
}

#loader {
  line-height: normal;

  span {
    display: inline-block;
  }
}

#modal-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  top: 2rem;
  left: 0;
  right: 0;
}
