@use "sass:color";
@import 'variables';

.modal-container {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
  background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
  backdrop-filter: blur(5px);
  transition: all 0.4s;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .modal-content {
    z-index: 1002;
    position: relative;
    background-color: #fff;
    padding: 2rem;
    margin: 5rem 2rem 2rem 2rem;
    width: 40rem;
    min-height: 15rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

    @media (max-width: $screen-md) {
      width: 100%;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;

      .column {
        flex: 1;
        margin: 0 1rem;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    h1 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 400;
    }
    hr {
      border: 0;
      height: 1px;
      background: $gray-lighter;
      margin: 1.5rem 0;
    }
    .close {
      color: $gray;
      float: right;
      padding: 0;
      margin: -0.5rem -0.5rem 0 0;
      line-height: 1rem;
      font-size: 2rem;
      font-weight: 400;
      cursor: pointer;
      background: transparent;
      outline: none;
      border: 0;

      svg {
        fill: currentColor;
        height: 2rem;
        width: 2rem;
      }
    }
    .center {
      text-align: center;
    }
    p {
      margin: 0 0 1rem 0;
      font-size: 1rem;

      &.score {
        text-align: center;
        font-weight: 700;
        font-size: 1.6rem;
        margin: 0 0 0.2rem 0;
      }
      &.moves {
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
        margin: 0 0 1.5rem 0;
      }
    }
    .cta {
      text-align: center;
      margin-top: 2rem;

      button {
        cursor: pointer;
        color: $text-color;
        padding: 1rem 2rem;
        border: 0;
        border-radius: 2rem;
        background-color: $cta-light;
        background: linear-gradient(to bottom, $cta-light, $cta-dark);
        color: #fff;
        outline: none;
        font-weight: 700;
        font-size: 1.2rem;

        &:hover, &:focus {
          background: linear-gradient(to bottom,
            color.adjust($cta-light, $lightness: -10%),
            color.adjust($cta-dark, $lightness: -10%));
        }
        &:active {
          background: $cta-dark;
        }
      }
    }
    nav {
      float: right;
      button {
        color: $text-color;
        padding: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        outline: none;
        font-weight: 700;
        font-size: 1.2rem;
        margin-left: 1rem;

        &:disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
}
