@import 'variables';

table {
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;

  tr {
    &:first-child {
      font-weight: 500;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-lighter;
    }

    td {
      margin: 0;
      padding: 0.2rem;

      &:first-child {
        text-align: left;
      }
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
}
