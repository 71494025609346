@import 'variables';

form {
  margin-top: 1rem;

  input, textarea, select, button {
    color: inherit;
    background: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    border: 1px solid $gray-light;
    outline: none;
  }
  input[type=text] {
    width: 15rem;

    &::placeholder {
      color: $gray-light;
    }
  }
  button {
    margin: 0 0 0 0.5rem;
    font-weight: 500;

    &:active {
      background: $gray-lighter;
    }
  }
}
